.customers {
	width: 100%;
	height: 100%;
}

@media only screen and (min-width: 768px) {
	.customers {
		display: grid;
		width: 100%;
		height: 100%;
		grid-template-columns: 250px auto;
	}
}

/* Side-panel */
.customers .bm-menu .add-customer-wrapper {
	margin: 10px 0px 10px 0px;
	padding: 0px 5px 0px 5px;
}

/* Main-panel */
.customers .main-panel {
	padding: 10px;
}

.customers .main-panel .title {
	text-align: center;
}

.customers .main-panel table tbody > tr {
	cursor: pointer;
}

.customers .main-panel .loading-spinner-wrapper {
	width: 100%;
	height: calc(100% - 40px);
	display: flex;
}

.customers .main-panel .loading-spinner-wrapper > * {
	margin: auto;
}