.edit-modal .edit-customer-title {
	margin-bottom: 20px;
	font-weight: bold;
}

.edit-modal .loading-spinner-wrapper {
	width: 100%;
	text-align: center;
}

.edit-modal .modal-body .form-label {
	font-weight: bold;
}

.edit-modal .modal-body .login-allowed-checkbox {
	margin-top: 10px;
}