.contacts {
	margin: 10px 0px;
	max-height: 40vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.contacts .contact {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 5px;
	grid-row-gap: 2px;
	margin: 10px 0px;
}

.contacts .contact > *:not(.txtarea) {
	grid-column: 1/2;
}

.contacts .contact .txtarea {
	grid-column: 2/3;
	grid-row: 1/3;
}

.contacts .contact .btn {
	grid-column: 2/3;
	grid-row: 3/4;
}