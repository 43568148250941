.device-select .loading-spinner-wrapper {
	width: 100%;
	text-align: center;
	padding: 10px 0px;
}

.device-select {
	width: 100%;
	text-align: center;
}

.device-select .table-header {
	margin: 10px 0px 0px 0px;
	padding: 10px;
	background-color: darkgray;
}

.device-select .table-wrapper {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 25vh;
}

.device-select .device-table > * {
	cursor: pointer;
}

.device-select .device-table .list-item {
	background-color: #FFFFFF;
}

.device-select .device-table .list-item.active {
	background-color: rgb(200, 214, 255);
}

.device-select .device-dropdown > .dropdown-toggle,
.device-select .device-dropdown > .dropdown-menu {
	width: calc(100% - 10px);
}

.device-select .device-dropdown > .dropdown-menu {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 25vh;
}