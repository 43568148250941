.analytics {
	width: 100%;
	height: 100%;
}

/* Side-panel */
.analytics .bm-menu .time-selector {
	margin: 20px 0px;
}

/* Main-panel */
.analytics .main-panel {
	padding: 10px;
	height: 100%;
}

.analytics .main-panel .title {
	text-align: center;
}

.analytics .main-panel .loading-spinner-wrapper {
	text-align: center;
	position: relative;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
}

.analytics .main-panel .loading-spinner-wrapper > * {
	margin: auto;
}

.analytics .main-panel .plotly-wrapper {
	height: calc(100% - 50px);
	background-color: #FFFFFF;
}