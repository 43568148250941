.change-password {
	width: 100%;
	height: 100%;
	display: flex;
}

.change-password .content-wrapper {
	flex: 0 1 auto;
	margin: auto;
}

.change-password .content-wrapper .change-password-form {
	padding: 20px;
	border-radius: 5px;
	background-color: #FFFFFF;
	box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.1);
	text-align: center;
}

.change-password .content-wrapper .change-password-form .title {
	font-size: 25px;
	font-weight: bold;
	text-align: left;
	margin-bottom: 20px;
}

.change-password .content-wrapper .change-password-form .form-label {
	font-weight: bold;
}

.change-password .content-wrapper .change-password-form .input-field {
	display: block;
	margin-top: 20px;
	text-align: left;
}


.change-password .content-wrapper .change-password-form .check{
	text-align: left;
}

.change-password .content-wrapper .change-password-form .btn {
	margin-top: 20px;
}
