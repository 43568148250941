.device-daily-view {
	height: 100%;
	padding: 10px;
}

.device-daily-view .controls {
	margin-bottom: 10px;	
}

.device-daily-view .plotly-wrapper {
	height: calc(100% - 48px);
	background-color: #FFFFFF;
}

/* Spinner */
.device-daily-view .loading-spinner-wrapper {
	position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
}