.users {
	height: 100%;
	width: 100%;
}

@media only screen and (min-width: 768px) {
	.users {
		display: grid;
		width: 100%;
		height: 100%;
		grid-template-columns: 250px auto;
	}
}

/* Side panel */
.users .bm-menu .add-user-wrapper {
	margin: 10px 0px 10px 0px;
	padding: 0px 5px 0px 5px;
}

.users .main-panel {
	height: 100%;
	padding: 10px;
}

.users .main-panel .title {
	text-align: center;
}

.users .main-panel .loading-spinner-wrapper {
	text-align: center;
	position: relative;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
}

.users .main-panel .loading-spinner-wrapper > * {
	margin: auto;
}

.users .main-panel .users-table tbody tr > * {
	cursor: pointer;
}