.edit-modal .edit-device-title {
	margin-bottom: 10px;
	font-weight: bold;
}

.edit-modal .modal-body .form-label {
	font-weight: bold;
}

.edit-modal .modal-body .form-label:not(:first-child) {
	margin-top: 10px;
}

.edit-modal .modal-body input:last-child {
	margin-bottom: 10px;
}

.edit-modal .location {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
}