.navbar {
	padding-top: 0px;
	padding-bottom: 0px;
}

.navbar .navbar-brand {
	cursor: pointer;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 2px;
	padding-bottom: 2px;
}

.navbar .navbar-brand .brand-icon {
	max-height: 35px;
}

.navbar .navbar-nav .navitem.dropdownitem {
	padding: 4px 24px;
	cursor: pointer;
}

.navbar .navbar-nav .link-mimic {
	color: rgba(255, 255, 255, 0.5);
	padding: 0.5rem;
	cursor: pointer;
}

.navbar .navbar-nav .link-mimic.active {
	color: #FFFFFF;
}

.navbar .navbar-nav .link-mimic:hover {
	color: rgba(255, 255, 255, 0.75);
}