.add-customer-modal-form .modal-body .form-label {
	font-weight: bold;
}

.add-customer-modal-form .modal-body .form-label:not(:first-child) {
	margin-top: 10px;
}

.add-customer-modal-form .modal-body input:last-child {
	margin-bottom: 10px;
}

.add-customer-modal-form .login-allowed-checkbox {
	margin-top: 10px;
}