.edit-modal .edit-user-title {
	margin-bottom: 20px;
	font-weight: bold;
}

.edit-modal .user-permissions {
	margin-top: 10px;
}

.edit-modal .modal-body .form-label {
	font-weight: bold;
}

.edit-modal .modal-body .edits .edit-label.notification-email {
	margin-top: 10px;
}