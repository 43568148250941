/* Position and sizing of burger button */
.bm-burger-button {
	position: fixed;
	width: 36px;
	height: 30px;
	left: 10px;
	top: 45px;
}
  
/* Color/shape of burger icon bars */
.bm-burger-bars {
	background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
	background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
	height: 24px;
	width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
	background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
	position: fixed;
	height: 100%;
}

/* General sidebar styles */
.bm-menu {
	background: #373a47f6;
	padding: 2.5em 0 0;
	font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
	fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
	color: #FFFFFF;
	padding: 0px;
	margin: 0px;
	overflow: hidden;
}

/* Individual item */
.bm-item {
	display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
	background: rgba(255, 255, 255, 0.0);
}