.language-select-dropdown .dropdown-toggle {
	padding: 4px 24px;
	cursor: pointer;
}

.language-select-dropdown .dropdown-toggle:hover{
	background-color: #f8f9fa;
}

.language-select-dropdown .dropdown-menu {
	top: -4px;
	left: calc(-100% + 0px);
}