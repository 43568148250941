.devices {
	height: 100%;
	width: 100%;
}

@media only screen and (min-width: 768px) {
	.devices {
		display: grid;
		width: 100%;
		height: 100%;
		grid-template-columns: 250px auto;
	}
}

.devices .bm-menu .add-device-wrapper {
	margin: 10px 0px 10px 0px;
	padding: 0px 5px 0px 5px;
}

.devices .main-panel {
	height: 100%;
	padding: 10px;
}

.devices .main-panel .title {
	text-align: center;
}

.devices .main-panel .loading-spinner-wrapper {
	text-align: center;
	position: relative;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
}

.devices .main-panel .loading-spinner-wrapper > * {
	margin: auto;
}