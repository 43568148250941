.set-password {
	background-color: #F5F5F5;
	width: 100%;
	height: 100%;
	display: flex;
}

.set-password .content-wrapper {
	flex: 0 1 auto;
	margin: auto;
	padding: 20px;
	border-radius: 5px;
	background-color: #FFFFFF;
	box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.1);
}

.set-password .content-wrapper .success {
	text-align: center;
}

.set-password .content-wrapper .loading-spinner-wrapper {
	width: 100%;
	text-align: center;
}


.set-password .content-wrapper .icon-wrapper {
	text-align: center;
	margin-bottom: 10px;
}

.set-password .content-wrapper .status-icon {
	margin-bottom: 0px;
}

.set-password .content-wrapper .checks {
	margin-bottom: 0px;
}

.set-password .content-wrapper .title {
	font-size: 25px;
	font-weight: bold;
}

.set-password .content-wrapper > * {
	margin-bottom: 20px;
}

.set-password .content-wrapper form {
	margin-bottom: 20px;
}

.set-password .content-wrapper form .form-label {
	margin-top: 20px;
}

.set-password .content-wrapper form .btn-wrapper {
	width: 100%;
	text-align: center;
	margin-top: 20px;
}