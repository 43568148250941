.tablets {
	overflow-y: auto;
	height: 100%;
}

.tablet-wrapper {
	width: 100%;
	margin: 0px;
	padding: 0px;
}

.tablet {
	min-width: 400px;
	margin: 5px auto 5px auto;
}

.tablet .tablet-header {
	cursor: pointer;
	background-color: #6c757d;
	color: #FFFFFF;
}

.tablet .tablet-header .time .minor {
	font-weight: bold;
}

.tablet .card-body {
	padding: 2px 0px 0px 0px;
	border-bottom: 1px solid grey;
	border-left: 1px solid grey;
	border-right: 1px solid grey;
	border-radius: 0px 0px 5px 5px;
	overflow: hidden;
}

.tablet .card-body .content {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
}


/* Spinner */
.tablet .card-body .content .loading-spinner-wrapper {
	width: 100%;
	margin: 20px auto;
	grid-column: 1/13;
}




.tablet .card-body .content .tablet-box {
	display: grid;
	grid-template-columns: 50px auto;
	background-color: rgb(249, 247, 255);
}

.tablet .card-body .content .tablet-box:nth-child(4n+1),
.tablet .card-body .content .tablet-box:nth-child(4n) {
	background-color: rgb(231, 227, 241);
}

.tablet .card-body .content .tablet-box * {
	cursor: pointer;
}

.tablet .card-body .content .tablet-box .main-wrapper {
	grid-column: 2/3;
}

.tablet .card-body .content .tablet-box .main-wrapper .title {
	margin: 0px 10px 0px 10px;
}

.tablet .card-body .content .tablet-box .icon-wrapper {
	grid-column: 1/2;
	max-width: 50px;
}

.tablet .card-body .content .tablet-box .icon-wrapper img {
	height: 100%;
	width: 100%;
}

.tablet .card-body .content .tablet-box .table-sub-value {
	grid-column: 1/3;
}

.tablet .card-body .content .tablet-box .secondary-values .value {
	margin: 0px 5px;
}


.pulse {
    animation: animate 2s linear infinite;
}

@keyframes pulse_animation {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 64, 0.7), 0 0 0 0 rgba(255, 0, 64, 0.7);
    }

    40% {
        box-shadow: 0 0 0 50px rgba(255, 0, 64, 0), 0 0 0 0 rgba(255, 0, 64, 0.7);
    }

    80% {
        box-shadow: 0 0 0 50px rgba(255, 0, 64, 0), 0 0 0 30px rgba(255, 0, 64, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 30px rgba(255, 0, 64, 0);
    }
}