.notification-body.wipe.red {
	animation-name: wipe;
	animation-duration: 4s;
	animation-iteration-count: infinite;
	background: linear-gradient( -90deg, transparent 0%, #cc3925 50%, #cc3925 50%, transparent 100%);
	background-size: 400%;
}

.notification-body.wipe.orange {
	animation-name: wipe;
	animation-duration: 4s;
	animation-iteration-count: infinite;
	background: linear-gradient( -90deg, transparent 0%, #ff9737 50%, #ff9737 50%, transparent 100%);
	background-size: 400%;
}

.notification-body.wipe.blue {
	animation-name: wipe;
	animation-duration: 4s;
	animation-iteration-count: infinite;
	background: linear-gradient( -90deg, transparent 0%, #3fb2ff 50%, #3fb2ff 50%, transparent 100%);
	background-size: 400%;
}

@keyframes wipe {
	0% {
		background-position: 100%;
	}
	50% {
		background-position: 0%;
	}
	100% {
		background-position: 100%;
	}
}