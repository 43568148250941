.manage-alerts {
	width: 100%;
	height: 100%;
}

.formCheckboxRow {
	padding: 10px 30px;
	border: 0px solid rgba(0,0,0,.2);
    border-radius: .3rem;
}

.manage-alerts .table tbody tr {
    width: 100%;
    margin-bottom: 1rem;
	color: #ffffff;
}

.manage-alerts .table-dark tbody {
    color: #fff;
    background-color: #251f1f;
}

.manage-alerts .table tbody tr {
    display: table-row;
    vertical-align: inherit;
	border-color: inherit;
	cursor: pointer;
}

.manage-alerts .loading-spinner-wrapper {
	text-align: center;
	position: relative;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
}

.manage-alerts .loading-spinner-wrapper > * {
	margin: auto;
}

.manage-alerts .add-alert-button {
	width: 100%;
	border-radius: 0px;
}

.manage-alerts .loading-spinner-wrapper {
	padding-top: 40%;
	width: 100%;
	height: 100%;
}

.add-alert-modal .modal-dialog {
	min-width: 410px;
}

.add-alert-modal .edit-alert-modal-form {
	min-width: 410px;
}

.edit-alert-modal .modal-dialog {
	min-width: 410px;
}

.edit-alert-modal .edit-alert-modal-form {
	min-width: 410px;
}



/*


body > div.fade.modal.show > div > div > form > div.modal-body > form > div > div.sendtype-sms-checkbox.form-check





.manage-alerts {
	width: 100%;
	height: 100%;
	display: flex;
}

.manage-alerts .content-wrapper {
	flex: 0 1 auto;
	margin: auto;
}

*/