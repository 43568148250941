.login-with-carousel {
	width: 100%;
	height: 100%;

	display: flex;
	background-color: #F5F5F5;
}


.login-with-carousel .left-side-segment {
	width: 50%;
	height: 100%;
}

.login-with-carousel .right-side-segment {
	width: 50%;
	height: 100%;
	display: flex;
}


.login-with-carousel .right-side-segment .content-wrapper {
	margin: auto;
	flex: 0 1 auto;
}

.loginForm {
	padding: 20px;
	border-radius: 5px;
	background-color: #FFFFFF;
	box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.1);
	text-align: center;
}

.loginForm .title {
	font-size: 25px;
	font-weight: bold;
	margin-bottom: 20px;
}

.loginForm input {
	margin-bottom: 10px;
}


.loginForm .loading-spinner-wrapper {
	margin-bottom: 20px;
}


.login-with-carousel .right-side-segment .language-select {
	text-align: center;
	margin-top: 20px;
}

.login-with-carousel .right-side-segment .language-select .language-select-dropdown {
	display: inline-block;
	margin-left: auto;
}

.login-with-carousel .right-side-segment .language-select .language-select-dropdown .dropdown-toggle:hover {
	background-color: #FFFFFF;
}

.login-with-carousel .right-side-segment .language-select .language-select-dropdown {
	background-color: #FFFFFF;
	border-radius: 5px;
	box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.1);
}

.login-with-carousel .version {
	position: absolute;
	font-size: 10px;
	bottom: 0;
	right: 5px;
}


.loginForm .login-failed-msg {
	margin-bottom: 20px;
	color: #aa0c01;
	font-weight: bold;
}

.loginForm .login-failed-msg .icon-wrapper {
	text-align: center;
	margin-bottom: 10px;
}

.loginForm .login-failed-msg .status-icon {
	margin-bottom: 0px;
}