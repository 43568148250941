.add-device-modal-form .modal-body .form-label {
	font-weight: bold;
}

.add-device-modal-form .modal-body .form-label:not(:first-child) {
	margin-top: 10px;
}

.add-device-modal-form .modal-body input:last-child {
	margin-bottom: 10px;
}

.add-device-modal-form .location {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
}