.customer-select .loading-spinner-wrapper {
	width: 100%;
	text-align: center;
	padding: 10px 0px;
}

.customer-select {
	width: 100%;
	text-align: center;
}

.customer-select .customer-dropdown > .dropdown-toggle,
.customer-select .customer-dropdown > .dropdown-menu {
	width: calc(100% - 10px);
}

.customer-select .customer-dropdown > .dropdown-menu {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 25vh;
}

.customer-select .card-header {
	background-color: #FFFFFF;
	cursor: pointer;
	border-radius: 10px;
}

.customer-select .card-header:hover {
	background-color: #F9F9F9;
}

.customer-select .card {
	background-color: #F5F5F5;
}

.customer-select .card-body {
	background-color: #F5F5F5;
	border: 1px solid lightgray;
	border-radius: 5px;
	padding: 10px;
}