.daily-view {
	/* display: grid; */
	width: 100%;
	height: 100%;
	/* grid-template-columns: auto; */
	background-color: #F5F5F5;
}

/* Main-panel */
.daily-view .main-panel {
	height: 100%;
	width: 100%;
	overflow-y: auto;
}

.daily-view .main-panel .content.loading {
	display: block;
	text-align: center;
	height: calc(100% - 50px);
	overflow: hidden;
}

.daily-view .main-panel .content {
	width: 100%;
	padding: 10px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
	grid-template-rows: repeat(auto, 320px);
	grid-row-gap: 10px;
	background-color: #F5F5F5;
}

@media only screen and (max-width: 768px) {
	.daily-view .main-panel .content {
		width: 100%;
		padding: 10px;
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 10px;
		grid-template-rows: repeat(auto, 320px);
		grid-row-gap: 10px;
		background-color: #F5F5F5;
	}
}

.daily-view .main-panel .title {
	text-align: center;
}

.daily-view .main-panel .content .loading-spinner-wrapper {
	position: relative;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
}

.daily-view .main-panel .content .loading-spinner-wrapper > * {
	margin: auto;
}