.farm.home {
	min-height: 100%;
	background-color: #F5F5F5;
}

.farm.home > .content {
	max-width: 520px;
	margin: 0px auto auto auto;
}

@media only screen and (max-width: 768px) {
	.farm.home > .content {
		width: 100%;
	}
}

.farm.home > .content {
	text-align: center;
}

.farm.home > .loading-spinner-wrapper {
	position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
}